@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.pdfModal {
  [class*='Modal_modalContainer__'] {
    padding: 27px 18px;
  }

  [class*='Modal_modalContent__'] {
    padding: 48px 18px 16px;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
}

.pdfWrapper {
  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $brand-divider;
  }

  :global {
    .react-pdf__Page {
      border-bottom: 6px solid $brand-pdf-divider;

      @include breakpoint(md, min) {
        border-bottom: 10px solid $brand-pdf-divider;
      }
    }

    .react-pdf__Page:last-child {
      border-bottom: none;
    }
  }
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  height: 39px;
  width: 39px;
  color: $brand-slate-dark;
  cursor: pointer;
  z-index: 4;
}

button.closeIcon {
  @include resetButtonWrapper;
}

.noButton {
  @include resetButtonWrapper;
  text-align: left;
}

.jobCode {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px 18px 0px;
  font-size: $font-size-xs;
}
