@import 'styles/breakpoints';
@import 'styles/typography';

.nurseNavigator {
  @include caption-desktop;
  background: $brand-white;
  box-shadow: $nurse-navigator-drop-shadow;
  display: inline-flex;
  width: 50px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  border: 0;
  margin: 0;
  border-radius: 120px;
  color: $black-color;
  line-height: 1;
  text-decoration: none;
  position: fixed;
  bottom: 146px;
  right: 9px;
  z-index: 300;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;

  &.doubleLine {
    .nurseLabel {
      line-height: 1.2;
      span {
        display: block;
      }
    }
  }

  .nurseLabel {
    display: none;
    flex: 1;
    text-align: left;
  }

  &:hover {
    color: inherit;
  }

  .nurseBubble {
    display: inline-flex;
    height: 44px;
    width: 44px;
    justify-content: center;
    align-items: center;
    background: $brand-primary-tints-200;
    color: $text-color-primary;
    border-radius: 50%;
    position: relative;
    z-index: 20;

    svg {
      width: 22px;
      height: auto;
    }
  }

  &::after {
    content: '';
    width: 15px;
    height: 40px;
    display: inline-block;
    background: $brand-white;
    position: absolute;
    z-index: 10;
    right: 2px;
    bottom: -4px;
    transform-origin: bottom right;
    transform: skewX(53deg) rotate(48deg);
    border-radius: 6px;
    box-shadow: 0px 5px 8px 0 rgba(#2b2b2b, 0.02);
  }

  &:not(.compacted) {
    @include breakpoint($custom-nav-breakpoint, min) {
      width: 275px;
      padding: 2px 2px 2px 16px;
    }

    &::after {
      @include breakpoint($custom-nav-breakpoint, min) {
        box-shadow: 0px 10px 8px 3px rgba(#2b2b2b, 0.08);
      }
    }

    .nurseLabel {
      @include breakpoint($custom-nav-breakpoint, min) {
        display: inline-block;
      }
    }

    .nurseBubble {
      @include breakpoint($custom-nav-breakpoint, min) {
        height: 46px;
        width: 46px;
      }
    }
  }

  &.compacted {
    @include breakpoint($custom-nav-breakpoint, min) {
      bottom: 30px;
    }
  }
}
