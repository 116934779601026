@import 'styles/breakpoints';
@import 'styles/typography';

.wrapper {
  padding: 75px 0 75px;
  text-align: center;

  @include breakpoint(sm, min) {
    padding: 105px 0 162px;
  }
}

.code {
  color: $brand-primary;
  font-size: 8rem;
  line-height: 1.05;
  font-weight: $font-weight-medium;

  @include breakpoint(sm, min) {
    font-size: 11rem;
  }
}

.status {
  @include h1;
  margin-top: 25px;
  color: $brand-secondary;

  @include breakpoint(sm, max) {
    margin-top: 10px;
    font-size: $font-size-2xl;
  }
}
